import { CodeSurferColumns, Step } from "code-surfer";
import { demo, code, code2, deck } from "../../../../../../theme";
import { Picture, Example, Layout } from "../../../../../../layout";
import * as React from 'react';
export default {
  CodeSurferColumns,
  Step,
  demo,
  code,
  code2,
  deck,
  Picture,
  Example,
  Layout,
  React
};